:root { 
  --alto: #d9d9d9;
  --black: #000000;
  --black-2: #0000001f;
  --black-3: #0000004c;
  --black-rock: #000329;
  --blue-zodiac: #1b2547;
  --bon-jour: #e2e2e2;
  --eerie-black: #202020;
  --primaryblue-ryb: #0042ed;
  --primaryghost-white: #f4f6ff;
  --primaryorange: #f56600;
  --secondaryblue01: #1a59ff;
  --secondaryoxford-black02: #252846;
  --secondaryoxford-black03: #454761;
  --secondaryvivid-sky-blue04: #e8f9ff;
  --secondarywhite01: #ffffff;
  --shady-lady: #a9a9aa;
  --tuna: #34343e;
  --wild-sand: #f3f3f3;
  --woodsmoke: #0a0914;
  --woodsmoke-2: #0b0914;
 
  --font-size-l: 18px;
  --font-size-m: 16px;
  --font-size-s: 12px;
  --font-size-xl: 20px;
  --font-size-xxl: 24px;
  --font-size-xxxl: 36px;
  --font-size-xxxxl: 64px;
 
  --font-family-font_awesome_5_pro-regular: "Font Awesome 5 Pro-Regular", Helvetica;
  --font-family-inter: "Inter", Helvetica;
  --font-family-poppins: "Poppins", Helvetica;
}
