@import "https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css";
@import "https://fonts.googleapis.com/css?family=Poppins:400,600,300,500,700|Inter:700,600,400";
@import "https://px.animaapp.com/64ba23acc44a4924f0604360.64ba23adc44a4924f0604363.WVsVXfx.rcp.png";
@font-face {
  font-family: "Font Awesome 5 Pro-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("Font Awesome 5 Pro-Regular-400.d9cf9984.otf") format("opentype");
}

.screen a {
  text-decoration: none;
  display: contents;
}

* {
  box-sizing: border-box;
}

.tablet-small {
  background-color: var(--secondarywhite01);
  border: 1px;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.tablet-large {
  background-color: var(--secondarywhite01);
  border: 1px;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.mobile {
  background-color: var(--secondarywhite01);
  border: 1px;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.desktop {
  background-color: var(--secondarywhite01);
  border: 1px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

:root {
  --alto: #d9d9d9;
  --black: #000;
  --black-2: #0000001f;
  --black-3: #0000004c;
  --black-rock: #000329;
  --blue-zodiac: #1b2547;
  --bon-jour: #e2e2e2;
  --eerie-black: #202020;
  --primaryblue-ryb: #0042ed;
  --primaryghost-white: #f4f6ff;
  --primaryorange: #f56600;
  --secondaryblue01: #1a59ff;
  --secondaryoxford-black02: #252846;
  --secondaryoxford-black03: #454761;
  --secondaryvivid-sky-blue04: #e8f9ff;
  --secondarywhite01: #fff;
  --shady-lady: #a9a9aa;
  --tuna: #34343e;
  --wild-sand: #f3f3f3;
  --woodsmoke: #0a0914;
  --woodsmoke-2: #0b0914;
  --font-size-l: 18px;
  --font-size-m: 16px;
  --font-size-s: 12px;
  --font-size-xl: 20px;
  --font-size-xxl: 24px;
  --font-size-xxxl: 36px;
  --font-size-xxxxl: 64px;
  --font-family-font_awesome_5_pro-regular: "Font Awesome 5 Pro-Regular", Helvetica;
  --font-family-inter: "Inter", Helvetica;
  --font-family-poppins: "Poppins", Helvetica;
}

/*# sourceMappingURL=index.492d7394.css.map */
