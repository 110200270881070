@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Poppins:400,600,300,500,700|Inter:700,600,400");
/* The following line is used to measure usage of this code. You can remove it if you want. */
@import url("https://px.animaapp.com/64ba23acc44a4924f0604360.64ba23adc44a4924f0604363.WVsVXfx.rcp.png");

@font-face {
  font-family: "Font Awesome 5 Pro-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("static/fonts/Font Awesome 5 Pro-Regular-400.otf") format("opentype");
}


.screen a {
  display: contents;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
